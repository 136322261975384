
























































import DataContainer from "../common/DataContainer";
import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({
  props: ["value", "label"],
  data() {
    return {
      items: [],
      removedItems: {}
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
    calculatedValid(value) {
      this.$emit("validated", value);
    },
    items: {
      handler() {
        this.$emit("input", this.calculatedValue);
      },
      deep: true
    }
  },
  computed: {
    calculatedValue() {
      const v = {};
      for (const i in this.items) {
        if (this.removedItems[i]) {
          continue;
        }
        const item = this.items[i];
        v[item.key.value] = item.value.value;
      }
      return v;
    },
    calculatedValid() {
      for (const i in this.items) {
        if (this.removedItems[i]) {
          continue;
        }
        const item = this.items[i];
        if (!item.key.value) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    initialize() {
      this.options.content.addItemBtn.on.xClick = ({ self }) => {
        self.context().addItem();
      };
      this.setValue(this.value);
    },
    setValue(value) {
      if (JSON.stringify(value) === JSON.stringify(this.calculatedValue)) {
        return;
      }

      this.items = [];
      if (value) {
        for (const k in value) {
          this.addItem(k, value[k]);
        }
      }
    },
    addItem(key = "", value = "") {
      this.items.push({
        key: new DataContainer(key),
        value: new DataContainer(value)
      });
    },
    removeItem(i) {
      this.$set(this.removedItems, i, true);
      this.$emit("input", this.calculatedValue);
      this.$forceUpdate();
    }
  },
  extraOptions: {
    defaultClass: "keyValuesInput"
  }
});
